import React from "react";

const LoadHistory = (props) => {
  return (
    <div
      className="history-button"
      style={{
        backgroundColor: "var(--rm-bg-1)",
        color: "var(--rm-txt-4)",
        fontSize: "11px",
        lineHeight: "10px",
        borderRadius: "20px",
        whiteSpace: "nowrap",
        userSelect: "none",
        textAlign: "center",
        height: "auto",
        margin: "10px auto",
        padding: "5px",
        maxWidth: "120px",
        cursor: "pointer",
      }}
      onClick={() => {
        props.appState.loadMoreMessages(true);
      }}
    >
      <span>φόρτωση ιστορικού</span>
    </div>
  );
};

export default LoadHistory;
