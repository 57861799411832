import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";

const PersonalGroupModal = (props) => {
  const setPersonalGroupPanel = props.appState.setPersonalGroupPanel;

  const personalGroupPanel = props.appState.personalGroupPanel;
  const updateUserSettingsOnServer = props.appState.updateUserSettingsOnServer;
  const [listData, setListData] = useState({});
  const [isNewGroup, setIsNewGroup] = useState(true);
  const userSettings = props.appState.userSettings;
  const [existingGroup, setExistingGroup] = useState();

  useEffect(() => {
    console.log(props);

    if (Array.isArray(personalGroupPanel))
      setListData({ users: [...personalGroupPanel] });
    else
      setListData({
        currentName: personalGroupPanel.displayName,
        name: personalGroupPanel.displayName,
        id: personalGroupPanel.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Modal
      size="xl"
      show={true}
      onHide={() => setPersonalGroupPanel(null)}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header>
        <Modal.Title>Ομάδα {personalGroupPanel.GroupName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="settings-item">
            {!listData.id && (
              <div
                onClick={() => {
                  setIsNewGroup(true);
                }}
                style={{ marginTop: "10px", display: "flex" }}
              >
                <div style={{ marginTop: ".5px" }}>
                  <input
                    type="radio"
                    id="newGroup"
                    name="newGroup"
                    checked={isNewGroup}
                    defaultChecked={isNewGroup}
                    onChange={(e) => {
                      setIsNewGroup(!isNewGroup);
                    }}
                  />
                </div>
                <label style={{ paddingLeft: "6px" }} className="form-label">
                  Νέα Ομάδα
                </label>
              </div>
            )}
            {listData.id && <label className="form-label">Όνομα Ομάδας</label>}
            <input
              style={{ display: "block", fontSize: "12px", width: "100%" }}
              spellCheck="false"
              defaultValue={listData.name}
              disabled={!isNewGroup}
              onBlur={(e) => {
                if (e.target && e.target.value) {
                  const gname = e.target.value;
                  setListData((listData) => ({
                    ...listData,
                    name: gname,
                  }));
                }
              }}
            ></input>
            {!listData.id && (
              <div>
                <div
                  onClick={() => {
                    setIsNewGroup(false);
                  }}
                  style={{ marginTop: "10px", display: "flex" }}
                >
                  <div style={{ marginTop: ".5px" }}>
                    <input
                      type="radio"
                      checked={!isNewGroup}
                      id="newGroup"
                      name="newGroup"
                      defaultChecked={!isNewGroup}
                      onChange={(e) => {
                        setIsNewGroup(false);
                      }}
                    />
                  </div>
                  <label style={{ paddingLeft: "6px" }} className="form-label">
                    Επιλογή Ομάδας
                  </label>
                </div>
                <Select
                  styles={customStyles}
                  value={existingGroup}
                  isDisabled={isNewGroup}
                  isClearable={true}
                  onChange={(e) => {
                    console.log(e);
                    setExistingGroup(e);
                  }}
                  options={
                    userSettings.groups
                      ? userSettings.groups.map((i, index) => ({
                          label: i.name,
                          value: index,
                        }))
                      : []
                  }
                />
              </div>
            )}
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setPersonalGroupPanel(null)}>Ακύρωση</Button>
        <Button
          style={{ marginLeft: "10px" }}
          onClick={() => {
            console.log(listData);
            console.log(props.appState.userSettings);

            if (
              listData.id !== undefined &&
              listData.name &&
              listData.name.trim() !== ""
            ) {
              const us = { ...userSettings };
              us.groups[listData.id.split("#").join("")].name = listData.name;

              updateUserSettingsOnServer(us, true);
              setPersonalGroupPanel(null);
            } else if (existingGroup && !isNewGroup) {
              const us = { ...userSettings };
              const g = us.groups[existingGroup.value];
              const newUsers = [...new Set([...listData.users, ...g.users])];
              g.users = newUsers;
              updateUserSettingsOnServer(us, true);
              setPersonalGroupPanel(null);
            } else if (
              isNewGroup &&
              listData.name &&
              listData.name.trim() !== ""
            ) {
              updateUserSettingsOnServer(
                {
                  ...userSettings,
                  groups: userSettings.groups
                    ? [...userSettings.groups, listData]
                    : [listData],
                },
                true
              );
              setPersonalGroupPanel(null);
            }
          }}
        >
          ΟΚ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonalGroupModal;

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #555",
    boxShadow: "none",
    height: 32,
    minHeight: 32,
    backgroundColor: state.isDisabled ? "#eee" : "#fff",
    borderRadius: "0px",
    fontSize: "13px",
  }),

  valueContainer: (provided) => {
    const paddingTop = "0px";
    return { ...provided, paddingTop };
  },

  singleValue: (provided) => {
    const color = "#555";
    return { ...provided, color };
  },
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
};
