import React from "react";

import { BiPaste } from "react-icons/bi";
import { BiCut } from "react-icons/bi";
import { BiCopy } from "react-icons/bi";
import { TiDelete } from "react-icons/ti";

import { MdAttachFile } from "react-icons/md";
import { BiScreenshot } from "react-icons/bi";
import { IoMdQuote } from "react-icons/io";
import { FaMicrophoneAlt } from "react-icons/fa";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const MessageBar = (props) => {
  const selectedUsers = props.appState.selectedUsers;
  const addTextToNewMessage = props.appState.addTextToNewMessage;
  const getUserDisplayName = props.appState.getUserDisplayName;
  const getTitleFromselectedUsers = props.appState.getTitleFromselectedUsers;

  const userSettings = props.appState.userSettings;

  const setPersonalGroupPanel = props.appState.setPersonalGroupPanel;

  ///////////////////////////
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    if (!e.shiftKey && !e.ctrlKey) setAnchorEl(null);
    if (e.keyCode !== 27) addTextToNewMessage(e.target.textContent.trim() + (e.target.textContent.trim().length > 2 ? " " : ""));
  };
  ///////////////////////////
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
    typography: {
      body2: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "12px",
        fontWeight: "300",
      },
    },
  });

  return (
    <div className="message-bar">
      <div className="message-bar-label">προς</div>

      {selectedUsers.length > 0 && (selectedUsers.length > 1 || selectedUsers[0].startsWith("##")) && (
        <div
          className="message-bar-user info-button clickable-user"
          title={getTitleFromselectedUsers(undefined, props.appState.userId)}
          onClick={() => {
            setPersonalGroupPanel(selectedUsers.sort());
          }}
        >
          {getUserDisplayName(selectedUsers, "gen", props.appState.userId)}
        </div>
      )}

      {selectedUsers.length > 0 && selectedUsers.length === 1 && !selectedUsers[0].startsWith("##") && (
        <div
          className="message-bar-user"
          style={{
            fontSize: "13px",
            color: window.getAppState().connections
              ? window.getAppState().connections.filter((c) => c.userId === selectedUsers[0].toString()).length > 0
                ? "#ffffff"
                : "#bbbbbb"
              : "#bbbbbb",
          }}
          title={getTitleFromselectedUsers(undefined, props.appState.userId)}
        >
          {getUserDisplayName(selectedUsers, "gen")}
        </div>
      )}
      {selectedUsers.length > 0 && (
        <div
          className="message-bar-user-clear"
          onClick={() => {
            props.appState.setSelectedUsers([]);
          }}
        >
          <TiDelete />
        </div>
      )}

      <div className="message-bar-icon-bar">
        <div
          className="message-bar-icon pc-only"
          style={{
            fontSize: "14px",
            paddingTop: "3px",

            paddingRight: "3px",
          }}
          role="img"
          aria-label="Εμότζι"
          title="Εμότζι"
          onClick={(e) => {
            console.log(props.appState.inputPanelHeight);
            if (props.appState.inputPanelHeight !== 104) props.appState.setInputPanelHeight(104);
            else props.appState.setInputPanelHeight(250);
          }}
        >
          😀
        </div>
        <div
          className="message-bar-icon  pc-only"
          onClick={async (e) => {
            var txt;
            const inputEl = document.getElementById("message-textarea");
            var newMessage = inputEl.value;
            const selectionStart = inputEl.selectionStart;
            const selectionEnd = inputEl.selectionEnd;

            if (selectionStart === selectionEnd) {
              txt = newMessage;
            } else {
              txt = newMessage.substring(selectionStart, selectionEnd);
            }

            console.log(`@@coping-text:${txt}`);
            try {
              window.postMessageToApp({ copingText: txt });
            } catch {}
            navigator.clipboard.writeText(txt);
            inputEl.focus();
          }}
        >
          <BiCopy title="Αντιγραφή" />
        </div>
        <div
          className="message-bar-icon pc-only"
          onClick={(e) => {
            const inputEl = document.getElementById("message-textarea");
            var newMessage = inputEl.value;
            const selectionStart = inputEl.selectionStart;
            const selectionEnd = inputEl.selectionEnd;

            var txt;
            if (selectionStart === selectionEnd) {
              txt = newMessage;
              inputEl.value = "";
            } else {
              txt = newMessage.substring(selectionStart, selectionEnd);
              inputEl.value = newMessage.substring(0, selectionStart) + newMessage.substring(selectionEnd);
            }
            console.log(`@@coping-text:${txt}`);
            try {
              window.postMessageToApp({ copingText: txt });
            } catch {}
            navigator.clipboard.writeText(txt);
          }}
        >
          <BiCut title="Αποκοπή" />
        </div>
        <div
          className="message-bar-icon pc-only"
          onClick={async (e) => {
            try {
              const text = await navigator.clipboard.readText();
              addTextToNewMessage(text);
            } catch {
              console.log(`@@paste-text-to-message-textarea`);
              try {
                window.postMessageToApp({
                  pasteTextToMessageTextarea: true,
                });
              } catch {}
            }
          }}
        >
          <BiPaste title="Επικόλληση" />
        </div>
        <label title="Επισύναψη Αρχείων" id="attach-files" className="attach-files message-bar-icon" htmlFor="fileElem">
          <MdAttachFile />
        </label>
        {props.appState.winclient === "true" && (
          <div
            className="message-bar-icon"
            onClick={async (e) => {
              console.log(`@@take-screen-shot`);
              try {
                window.postMessageToApp({ takeScreenShot: true });
              } catch {}
            }}
          >
            <BiScreenshot title="Screenshot" />
          </div>
        )}
        {window.admin && (
          <div className="message-bar-icon" onClick={() => props.appState.setShowRecorder((prev) => !prev)}>
            <FaMicrophoneAlt style={{ opacity: "0.7", fontSize: "14px" }} title="Ηχογράφηση" />
          </div>
        )}
        <div className="message-bar-icon" onClick={handleClick}>
          <IoMdQuote style={{ opacity: "0.7" }} title="Αποθηκευμένες φράσεις" />
        </div>
        <ThemeProvider theme={darkTheme}>
          <Menu
            className="context-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {userSettings.savedPhrases &&
              userSettings.savedPhrases
                .filter((pp) => pp.length > 2)
                .sort()
                .map((p, index) => (
                  <MenuItem key={index} className="menu-item" dense onClick={handleClose}>
                    <span className="menu-item-div">{p}</span>
                  </MenuItem>
                ))}
            {userSettings.savedPhrases && userSettings.savedPhrases.filter((pp) => pp.length > 2).length > 0 && <Divider />}

            {userSettings.savedPhrases && userSettings.savedPhrases.filter((pp) => pp.length < 3).length > 0 && (
              <div className="menu-emoji-container">
                {userSettings.savedPhrases
                  .filter((pp) => pp.length < 3)
                  .map((p, index) => (
                    <span key={index} onClick={handleClose} className="menu-emoji">
                      {p}
                    </span>
                  ))}
              </div>
            )}
            {userSettings.savedPhrases && userSettings.savedPhrases.filter((pp) => pp.length < 3).length > 0 && <Divider />}

            <MenuItem
              className="menu-item"
              dense
              onClick={() => {
                var txt;
                const inputEl = document.getElementById("message-textarea");
                var newMessage = inputEl.value;
                const selectionStart = inputEl.selectionStart;
                const selectionEnd = inputEl.selectionEnd;

                if (selectionStart === selectionEnd) {
                  txt = newMessage.trim();
                } else {
                  txt = newMessage.substring(selectionStart, selectionEnd).trim();
                }
                let tmp = { ...userSettings };
                if (txt !== "" && (!tmp.savedPhrases || tmp.savedPhrases.filter((p) => p === txt).length === 0)) {
                  if (!tmp.savedPhrases) {
                    tmp.savedPhrases = [txt];
                  } else {
                    tmp.savedPhrases = [...tmp.savedPhrases, txt];
                  }
                  props.appState.updateUserSettingsOnServer(tmp);
                } else if (txt === "") {
                  props.appState.setModalData({
                    title: "Πρόβλημα",
                    msg: "Δεν υπάρχει κάποια φράση ή emoji στο τρέχον μήνυμα για να προστεθεί!",
                  });
                  setAnchorEl(null);
                } else if (tmp.savedPhrases.filter((p) => p === txt).length !== 0) {
                  props.appState.setModalData({
                    title: "Πρόβλημα",
                    msg: "H φράση ή το emoji στο τρέχον μήνυμα είναι αποθηκευμένη!",
                  });
                  setAnchorEl(null);
                }
              }}
            >
              Πρόσθεση
            </MenuItem>
            <MenuItem
              className="menu-item"
              dense
              onClick={() => {
                var txt;
                const inputEl = document.getElementById("message-textarea");
                var newMessage = inputEl.value;
                const selectionStart = inputEl.selectionStart;
                const selectionEnd = inputEl.selectionEnd;

                if (selectionStart === selectionEnd) {
                  txt = newMessage.trim();
                } else {
                  txt = newMessage.substring(selectionStart, selectionEnd).trim();
                }

                if (txt !== "") {
                  if (userSettings.savedPhrases && userSettings.savedPhrases.filter((p) => p === txt).length !== 0) {
                    let tmp = { ...userSettings };

                    if (tmp.savedPhrases) {
                      tmp.savedPhrases = [...tmp.savedPhrases.filter((p) => p.trim() !== txt.trim())];
                    }
                    props.appState.updateUserSettingsOnServer(tmp);
                  } else {
                    props.appState.setModalData({
                      title: "Πρόβλημα",
                      msg: "H φράση στο τρέχον μήνυμα δεν είναι αποθηκευμένη για να αφαιρεθεί!",
                    });
                    setAnchorEl(null);
                  }
                } else {
                  props.appState.setModalData({
                    title: "Πρόβλημα",
                    msg: "Δεν υπάρχει κάποια φράση ή emoji στο τρέχον μήνυμα για να αφαιρεθεί!",
                  });
                  setAnchorEl(null);
                }
              }}
            >
              Αφαίρεση
            </MenuItem>
          </Menu>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default MessageBar;
