import React, { useContext } from "react";

import { RiUserFill } from "react-icons/ri";
import { RiGroupFill } from "react-icons/ri";
import { RiGroupLine } from "react-icons/ri";
import { BiHide } from "react-icons/bi";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { BiRename } from "react-icons/bi";
import { MdRemoveCircle, MdGroupAdd } from "react-icons/md";
import { FiCheckSquare, FiSquare } from "react-icons/fi";

import { GoChevronDown } from "react-icons/go";
import { getUserFullName } from "../Tools";

import { appContext } from "./ChatRoom";

const UserListItem = (props) => {
  const appState = useContext(appContext);

  const connections = appState.connections;
  const setSelectedUsers = appState.setSelectedUsersEx;
  const selectedUsers = appState.selectedUsers;
  const expandedUser = props.expandedUser;
  const setExpandedUser = appState.setExpandedUser;

  const updateUserSettingsOnServer = appState.updateUserSettingsOnServer;
  const userSettings = appState.userSettings;
  const setPersonalGroupPanel = appState.setPersonalGroupPanel;

  const userId = appState.userId;

  const u = props.user;
  const multiSelect = window.getAppValue("multiSelect");
  const handleClick = (e) => {
    console.log(u.id, u.displayName);

    if (e.ctrlKey || multiSelect) {
      if (selectedUsers.includes(u.id)) {
        if (selectedUsers.length < 3 || u.id !== userId) {
          if (selectedUsers.length === 3 && u.id !== userId) {
            setSelectedUsers([...selectedUsers.filter((item) => item !== u.id && item !== userId)]);
          } else {
            setSelectedUsers([...selectedUsers.filter((item) => item !== u.id)]);
          }
        }
      } else {
        if (selectedUsers.length !== 1 || u.id !== userId) {
          if (selectedUsers.length > 0 && !selectedUsers.includes(userId) && u.id !== userId) {
            setSelectedUsers([...selectedUsers, u.id, userId]);
          } else {
            setSelectedUsers([...selectedUsers, u.id]);
          }
        }
      }
    } else {
      setSelectedUsers([u.id]);
      if (appState.mobileView === true) appState.setMobileView(false);
    }
  };

  return (
    <div
      title={u.data?.description ? u.data.description : ""}
      className={"list-group-item" + (selectedUsers.includes(u.id) ? " selected" : "") + (expandedUser === u.id ? " expanded" : "")}
      id={props.id ? props.id : `user-item-${u.id}`}
      key={u.id}
    >
      <div
        className={"list-group-item-inner"}
        onClick={handleClick}
        style={{
          color: connections ? (u.isGroup || connections.filter((c) => c.userId === u.id.toString()).length > 0 ? "var(--rm-txt-2)" : "var(--rm-txt-3)") : "var(--rm-txt-3)",
        }}
      >
        {props.onDelete && (
          <div>
            <MdRemoveCircle
              onClick={props.onDelete}
              style={{
                color: "red",
                cursor: "pointer",
                margin: "3px 0 3px 3px",
                opacity: ".7",
              }}
            />
          </div>
        )}

        {!props.fromGroupList && (
          <div className="list-group-item-icon">
            {!u.isGroup && <RiUserFill />}
            {u.isGroup && !u.isPublic && !u.id.startsWith("##") && <RiGroupLine />}
            {u.isGroup && u.isPublic && <RiGroupFill />}
            {u.isGroup && u.id.startsWith("##") && <BiHide />}
          </div>
        )}

        {multiSelect && (
          <div className={"list-group-item-multiselect"}>
            {selectedUsers.includes(u.id) && <FiCheckSquare />}
            {!selectedUsers.includes(u.id) && <FiSquare />}
          </div>
        )}

        <div className="list-group-item-text">{u.displayName}</div>
        {!props.fromGroupList && (
          <div
            className="list-group-item-info-button"
            onClick={(e) => {
              e.stopPropagation();
              setExpandedUser(expandedUser === u.id ? null : u.id);
            }}
          >
            <GoChevronDown />
          </div>
        )}
      </div>

      {expandedUser === u.id && (
        <div className="info-panel">
          {!u.isGroup && (
            <div>
              <div className="info-panel-row">
                <span className="info-panel-label">Τμήμα</span>
                <span className="info-panel-data">{u.data?.department ? u.data.department : <>&nbsp;</>}</span>
              </div>
              <div className="info-panel-row">
                <span className="info-panel-label">email</span>
                <span className="info-panel-data">{u.data?.email ? u.data.email : <>&nbsp;</>}</span>
              </div>

              <div className="info-panel-split-row">
                <span className="info-panel-split-col1">
                  <span className="info-panel-label">Θέση</span>
                  <span className="info-panel-data">{u.data?.client ? u.data.client : <>&nbsp;</>}</span>
                </span>
                <span className="info-panel-split-col2">
                  <span className="info-panel-label">Τηλέφωνο</span>

                  <span className="info-panel-data">
                    {u.data?.phone && (
                      <>
                        {u.data.phone}

                        {appState.winclient === "true" && (
                          <>
                            <span style={{ userSelect: "none", fontSize: "10px" }}>&nbsp;&nbsp;</span>
                            <a
                              className="clickable-user"
                              style={{ userSelect: "none", fontSize: "9px" }}
                              href={`call://phone:${u.data.phone}:${u.displayName}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Κλήση
                            </a>
                          </>
                        )}
                      </>
                    )}
                    {!u.data?.phone && <>&nbsp;</>}
                  </span>
                </span>
              </div>
              <div className="info-panel-split-row">
                <div
                  style={{
                    maxHeight: "18px",
                    float: "right",
                    width: "100%",
                  }}
                >
                  <span
                    title="Πρόσθεση Χρήστη σε Ομάδα"
                    style={{
                      color: "green",
                      float: "right",
                      minHeight: "18px",
                      cursor: "pointer",

                      fontSize: "18px",
                    }}
                    onClick={() => {
                      setPersonalGroupPanel([u.id]);
                    }}
                  >
                    <MdGroupAdd />
                  </span>
                </div>
              </div>
            </div>
          )}
          {u.isGroup && (
            <div className="info-panel-row">
              {u.isBroadcast && (
                <>
                  <span className="info-panel-label">Περιγραφή</span>
                  <span className="info-panel-data">{u.data?.description ? u.data.description : <>&nbsp;</>}</span>
                </>
              )}
              {!u.isBroadcast && (
                <>
                  {/* {u.data?.description && (
                    <>
                      <span className="info-panel-label">Περιγραφή</span>
                      <span className="info-panel-description">{u.data.description}</span>
                    </>
                  )} */}
                  <span className="info-panel-label">Χρήστες</span>
                  {Array.isArray(u.users) &&
                    u.users
                      .filter((uu) => appState.users.find((uuu) => !uu.id || (uuu.id === uu.id && uuu.status == 1)))
                      .map((uu) => {
                        if (!uu.id && uu.startsWith("#")) {
                          const tmp = appState.userList().filter((g) => g.id == uu)[0];
                          return {
                            id: uu,
                            isGroup: true,
                            displayName: tmp.displayName,
                            displayNameGen: tmp.displayNameGen,
                          };
                        } else
                          return {
                            id: uu.id ?? uu,
                            isGroup: false,
                            displayName: getUserFullName(uu.id ? uu.id : uu, null, appState.users, appState.userSettings.nameFirst),
                            displayNameGen: getUserFullName(uu.id ? uu.id : uu, "gen", appState.users, appState.userSettings.nameFirst),
                          };
                      })
                      .sort((a, b) => a.displayName.localeCompare(b.displayName))

                      .map((uuu) => (
                        <UserListItem
                          readOnly={true}
                          key={uuu.id}
                          fromGroupList={true}
                          user={uuu}
                          onDelete={
                            u.id.startsWith("##")
                              ? () => {
                                  appState.setModalData({
                                    title: "Επιβεβαίωση",
                                    msg: (
                                      <>
                                        Να διαγραφεί ο/η {uuu.displayName} από την ομάδα {u.displayName};
                                      </>
                                    ),
                                    isQuestion: true,
                                    onOk: () => {
                                      if (u.id.startsWith("##")) console.log(u.id, uuu.id, u.displayName);

                                      var newGroups = [...userSettings.groups];
                                      var gp = newGroups.filter((g) => g.name === u.displayName)[0];
                                      gp.users = gp.users.filter((u) => u !== uuu.id);

                                      updateUserSettingsOnServer(
                                        {
                                          ...userSettings,
                                          groups: newGroups,
                                        },
                                        true
                                      );
                                    },
                                  });
                                }
                              : undefined
                          }
                        />
                      ))}
                  {u.id.startsWith("##") && (
                    <>
                      <span
                        title="Διγαρφή Ομάδας"
                        style={{
                          color: "red",
                          float: "right",
                          minHeight: "18px",
                          cursor: "pointer",
                          margin: "3px",
                        }}
                        onClick={() => {
                          appState.setModalData({
                            title: "Επιβεβαίωση",
                            msg: <>Να διαγραφεί η ομάδα {u.displayName};</>,
                            isQuestion: true,
                            onOk: () => {
                              if (selectedUsers.includes(u.id)) {
                                appState.setSelectedUsersEx([...selectedUsers.filter((item) => item !== u.id)]);
                              }
                              updateUserSettingsOnServer(
                                {
                                  ...userSettings,
                                  groups: [...userSettings.groups.filter((l) => l.name !== u.displayName)],
                                },
                                true
                              );
                            },
                          });
                        }}
                      >
                        <RiDeleteBin2Fill />
                      </span>
                      <span
                        title="Αλλαγή Ονόματος Ομάδας"
                        style={{
                          color: "green",
                          float: "right",
                          minHeight: "18px",
                          cursor: "pointer",
                          margin: "3px",
                        }}
                        onClick={() => {
                          console.log(u.displayName, u.id);
                          setPersonalGroupPanel({
                            displayName: u.displayName,
                            id: u.id,
                          });
                        }}
                      >
                        <BiRename />
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserListItem;
