import React from "react";
import { useContext } from "react";

import { stripHtml, isVisible } from "../Tools";
import { BiCopy } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { BsReply } from "react-icons/bs";
import { AiFillPushpin, AiOutlinePushpin } from "react-icons/ai";
import AudioPlayer from "./Audio";
//import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { appContext } from "./ChatRoom"

var dateFormat = require("dateformat");


  
const MessageItem = React.memo((props) => {
  const appState = useContext(appContext);
  const userId = props.userId;
  const setSelectedUsersEx = window.getAppState().setSelectedUsersEx;
  const setNewMessage = window.getAppState().setNewMessage;
  const setNewMessageData = window.getAppState().setNewMessageData;
  const getTitleFromselectedUsers = window.getAppState().getTitleFromselectedUsers;
  const darkTheme = window.getAppState().userSettings.darkTheme;
  const getUserDisplayName = window.getAppState().getUserDisplayName;
  const rootURL = window.getAppState().rootURL;

  const sendSystemMessage = window.getAppState().sendSystemMessage;

  var mData = {};
  try {
    mData = props.message.data ? JSON.parse(props.message.data) : {};
  } catch { }

  var message = {
    ...props.message,
    ...mData,
  };
  message.data = null;

  return (
    <div key={props.id} className={`message-item ${message.sendByMe ? "my-message" : "received-message"}`}>
      <>
        <div className="d-flex message-item-menu"></div>
        <div>
          <div className="message-item-header">
            <span className="message-sub-item">{message.sendByMe ? "προς " : ""}</span>

            {isVisible && (
              <>
                <span
                  title={message.sendByMe ? getTitleFromselectedUsers(message.to, message.from) : ""}
                  className="clickable-user link"
                  onClick={() => {
                    setSelectedUsersEx(message.sendByMe ? message.to.split(",") : message.from.split(","));
                  }}
                >
                  {getUserDisplayName(message.sendByMe ? message.to : message.from, message.sendByMe ? "gen" : "", message.from)}
                </span>
                {(message.sendByMe ? message.from : message.to) !== userId && (
                  <>
                    <span className="message-sub-item">{message.sendByMe ? "" : " προς "}</span>
                    <span
                      title={getTitleFromselectedUsers(message.to, message.from)}
                      className="clickable-user link"
                      onClick={() => {
                        setSelectedUsersEx(message.sendByMe ? message.from.split(",") : message.to.split(","));
                      }}
                    >
                      {getUserDisplayName(
                        message.sendByMe ? message.from : message.to,
                        "gen"
                        //message.from
                      )}
                    </span>
                  </>
                )}
              </>
            )}
            {!isVisible && <>...</>}
          </div>
        </div>

        <hr className="message-header-line" />

        <div>
          {message.body && message.body.toString().trim() !== '' && (
            <div className="message-item-body">
              {message.body.toString().trim().startsWith("~") && <pre>{message.body.trim().substring(1)}</pre>}
              {!message.body.toString().trim().startsWith("~") && (
                <pre
                  className="message-item-body"
                  dangerouslySetInnerHTML={{
                    __html: message.body.replaceAll("\n", "<br/>"),
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div>
          {message.files &&
            message.files.length > 0 &&
            message.files.map((f, index) => {
              return (
                <div key={index} style={{ margin: "10px 0" }}>
                  {f.name.toLowerCase().endsWith("webm") && <AudioPlayer src={`${rootURL}/files/${f.id}_${f.name}`} />}
                  <a key={index} href={`${rootURL}/files/${f.id}_${f.name}`} target="_blank" rel="noopener noreferrer">
                    {(f.name.toLowerCase().endsWith("jpeg") ||
                      f.name.toLowerCase().endsWith("jpg") ||
                      f.name.toLowerCase().endsWith("gif") ||
                      f.name.toLowerCase().endsWith("png")) && (
                        <img
                          onLoad={() => {
                            const myDiv = document.getElementsByClassName("history-panel")[0];

                            if (myDiv) {
                              myDiv.lastElementChild.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }}
                          src={`${rootURL}/files/${f.id}_${f.name}`}
                          alt={f.name}
                          title={f.name}
                          style={{
                            display: "block",
                            maxWidth: "250px",

                            margin: "3px",
                            cursor: "pointer",

                            objectFit: "scale-down",
                          }}
                        />
                      )}

                    {!(
                      f.name.toLowerCase().endsWith("webm") ||
                      f.name.toLowerCase().endsWith("jpeg") ||
                      f.name.toLowerCase().endsWith("jpg") ||
                      f.name.toLowerCase().endsWith("gif") ||
                      f.name.toLowerCase().endsWith("png")
                    ) && <div className="message-item-body">{f.name}</div>}
                  </a>
                </div>
              );
            })}
        </div>
        <div className="message-item-footer">
          {!isVisible && <>...</>}
          {isVisible && (
            <>
              <span
                title={(message.tags + "#").includes("#pin#") ? "Καρφίτσωμα" : "Ξεκαρφίτσωμα"}
                className="message-item-footer-icon"
                onClick={async (e) => {
                  if ((message.tags + "#").includes("#pin#")) {
                    sendSystemMessage(`@@removeTagFromMessage ${message.id} #pin`);
                  } else {
                    sendSystemMessage(`@@addTagToMessage ${message.id} #pin`);
                  }
                }}
              >
                {!(message.tags + "#").includes("#pin#") && (
                  <AiOutlinePushpin
                    title="Καρφίτσωμα.&#10;Το μήνυμα παραμένει στην λίστα και μετά το καθάρισμα"
                  />
                )}
                {(message.tags + "#").includes("#pin#") && (
                  <AiFillPushpin
                    title="Ξεκαρφίτσωμα"
                    style={{
                      color: darkTheme ? "yellowgreen" : "red",
                      opacity: "1 !important",
                    }}
                  />
                )}
              </span>

              <span
                title="Αντιγραφή"
                className="message-item-footer-icon"
                onClick={async (e) => {
                  var txt = message.body;
                  console.log(message.body);

                  if (!txt.startsWith("~")) txt = stripHtml(txt);
                  else txt = txt.substring(1);

                  console.log(`@@coping-text:${txt}`);
                  navigator.clipboard.writeText(txt);
                }}
              >
                <BiCopy title="Αντιγραφή" />
              </span>
              {message.sendByMe == true && <span
                
                title="Διαγραφή&#10;Το μήνυμα θα διαγραφεί οριστικά, μετά την επιβεβαίωση και από τον/τους παραλήπτες"
                className="message-item-footer-icon"
                onClick={async (e) => {
                
                  appState.setModalData({
                    title: "Διαγραφή μηνύματος",
                    msg: "Να διαγραφεί το μήνυμα;",
                    isQuestion: true,
                    onOk: () => {
                      sendSystemMessage(`@@addTagToMessage ${message.id} #del`);
                    },
                  });
                   
                 
                }}
              >
                <BiTrash  title="Διαγραφή&#10;Το μήνυμα θα διαγραφεί οριστικά και από τον/τους παραλήπτες" />
              </span>}
              <span
                title="Προώθηση"
                className="message-item-footer-icon"
                onClick={async (e) => {
                  var txt = message.body;
                  console.log(message.body);

                  if (!txt.startsWith("~")) txt = stripHtml(txt);
                  else txt = txt.substring(1);

                  window.messageData = [];
                  message.body.split("<a").forEach((link) => {
                    if (link.includes(">")) {
                      var tmp = link.split(">")[1].split("<")[0];
                      window.messageData.push(tmp);
                    }
                  });

                  setNewMessage(txt);
                  setNewMessageData({ files: message.files });
                }}
              >
                <BsReply title="Προώθηση" />
              </span>
            </>
          )}
          <span className="message-item-footer-text">
            {/* if message.time from previous year add year */}
            {dateFormat(message.time, `ddd d/m/yy HH:MM:ss`)
              .replace("Sun", "Κυρ")
              .replace("Mon", "Δευ")
              .replace("Tue", "Τρι")
              .replace("Wed", "Τετ")
              .replace("Thu", "Πεμ")
              .replace("Fri", "Παρ")
              .replace("Sat", "Σαβ")}
          </span>
        </div>
      </>
    </div>
  );
});

export default MessageItem;
