import React from "react";

import { stingForFilter } from "../Tools";
import UserListItem from "./UserListItem";
import UsersBar from "./UsersBar";
import UsersBarBottom from "./UsersBarBottom";
var dateFormat = require("dateformat");

const SidePanel = React.memo((props) => {
  const filter = stingForFilter(props.appState.usersFilter);
  const usersTab = props.appState.usersTab;
  const conversations = props.appState.conversations;
  const getUserDisplayName = props.appState.getUserDisplayName;
  const activeMainTab = props.appState.activeMainTab;

  const filteredUserList = () => {
    var tmp = props.appState.userList().filter((u) => filter === "" || u.filterData.includes(filter));

    if (tmp.length === 1) {
      props.appState.setExpandedUser(tmp[0].id);
    }

    return tmp;
  };

  const expandedUser = props.appState.getExpandedUser();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--rm-bg-2)",
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      <div >
        <UsersBar {...props} />
        <div style={{ minHeight: "2px", backgroundColor: "var(--rm-bg-2)" }} />
      </div>
      {usersTab === 0 && conversations && (
        <div className="conversations-container">
          {conversations.map((c) => (
            <div
              className={`${activeMainTab === c.id ? "conversation-container-selected" : "conversation-container"}${c.unRead ? " conversation-container-unread" : ""}`}
              key={c.id}
              onClick={() => {
                props.appState.setActiveMainTabFromUsers(c.id, c.label);
                if (props.appState.mobileView === true) props.appState.setMobileView(false);
              }}
            >
              <div title={c.displayName ? c.displayName.toString().split(", ").join("\n") : ""} className="conversation-id">
                {getUserDisplayName(c.label, "", props.appState.userId)}
              </div>

              {c.msg?.trim().startsWith("~") && (
                <pre className="conversation-last-msg">
                  <span className="conversation-last-msg-text">
                    <span className="conversation-last-msg-from">{props.appState.userId === c.from ? "Εγώ: " : (() => {
                      try {
                        let uu = props.appState.users.filter((u) => u.id === c.from)[0]
                        if (uu.firstName != '') return uu.firstName; else return uu.lastName;
                      } catch (e) {
                        return c.from
                      }

                    })()+": "}</span>
                    {c.msg?.trim().substring(1)}
                  </span>
                </pre>
              )}
              {!c.msg?.trim().startsWith("~") && (
                <pre
                  className="conversation-last-msg"
                  dangerouslySetInnerHTML={{
                    __html:
                      '<span class="conversation-last-msg-text">' +
                      '<span class="conversation-last-msg-from">' +
                      (props.appState.userId === c.from ? "Εγώ" : (() => {
                        try {
                          let uu = props.appState.users.filter((u) => u.id === c.from)[0]
                          if (uu.firstName != '') return uu.firstName; else return uu.lastName;
                        }
                        catch (e) {
                          return c.from
                        }

                      })()) +
                      ": " +
                      "</span>" +
                      (c.msg && c.msg.trim() !== "" ? c.msg.replaceAll("\n", "<br/>") : "...") +
                      "</span>",
                  }}
                />
              )}

              <div className="conversation-last-date">
                {" "}
                {dateFormat(c.date, "ddd d/m HH:MM")
                  .replace("Sun", "Κυρ")
                  .replace("Mon", "Δευ")
                  .replace("Tue", "Τρι")
                  .replace("Wed", "Τετ")
                  .replace("Thu", "Πεμ")
                  .replace("Fri", "Παρ")
                  .replace("Sat", "Σαβ")}
              </div>
            </div>
          ))}
        </div>
      )}
      {usersTab === 1 && (
        <>
          <div className="custom-scroll">
            <ul className="list-group">
              {filteredUserList()
                .filter((u) => !u.isGroup)
                .map((u) => (
                  <UserListItem id={`user-list-item-${u.id}`} key={u.id} user={u} expandedUser={expandedUser} />
                ))}
            </ul>
          </div>

          <UsersBarBottom appState={props.appState} type={"users"} />
        </>
      )}

      {usersTab === 2 && (
        <>
          <div className="custom-scroll">
            <ul className="list-group">
              {filteredUserList()
                .filter((u) => u.isGroup)
                .map((u) => (
                  <UserListItem id={`user-list-item-${u.id}`} key={u.id} user={u} expandedUser={expandedUser} />
                ))}
            </ul>
          </div>

          <UsersBarBottom appState={props.appState} type={"groups"} />
        </>
      )}
    </div>
  );
});

export default SidePanel;
