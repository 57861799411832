import React, { useRef } from "react";

//import ScrollableFeed from "react-scrollable-feed";

import Message from "./MessageItem";
//import Message from "./Message";
import LoadHistory from "./LoadHistory";

const HistoryPanel = (props) => {
  const messages = props.appState.messages;
  const noUserMessages = props.appState.noUserMessages;
  const activeMainTab = props.appState.activeMainTab;

  const messageProps = {
    userId: props.appState.userId,
    setSelectedUsers: props.appState.setSelectedUsers,
    nameFirst: props.appState.userSettings.nameFirst,
  };

  const scroll = useRef();
  window.scrollToBottom = () => {
    try {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    } catch (error) {}
  };

  const messagesToShow = () => {
    let m = [...messages];

    if (activeMainTab === "0") {
      m = [...m, ...noUserMessages];
    } else if (activeMainTab.includes(",")) {
      m = [...m, ...noUserMessages.filter((m) => [m.to, m.from].sort().join(",") === activeMainTab)];
    } else {
      m = [...m, ...noUserMessages.filter((m) => m.to === activeMainTab)];
    }
    
    m = m.sort((a, b) => {
      return new Date(b.time) - new Date(a.time);
    });
    return m;
  };

  return (
    <div
      className="custom-scroll master-history-panel"
      id="scrollableDiv"
      ref={scroll}
      style={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
      }}
      onScroll={() => {
        if (scroll.current.scrollHeight - scroll.current.offsetHeight + scroll.current.scrollTop <= 2 && (props.appState.autoLoad === 1 || props.appState.activeMainTab !== "0")) {
          props.appState.loadMoreMessages(true);
          console.log("topScroll");
        }
      }}
    >
      {messages &&
        messagesToShow().map((message, i) => (
          <div style={{ position: "relative" }} key={i} className="message-item-container">
            {message.id === "@@history" && <LoadHistory {...props} />}
            {!message.id.startsWith("@@") && <Message style={tempStyle} key={i} no={i} id={i} message={message} {...messageProps} tags={message.tags} />}
          </div>
        ))}
    </div>
  );
};

export default HistoryPanel;

const tempStyle = {
  color: "whitesmoke",
  minHeight: "80px",
};
