import React, { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
import { Modal, Button } from "react-bootstrap";

const SettingsPanel = (props) => {
  const userSettings = props.appState.userSettings;
  const setUserSettings = props.appState.setUserSettings;

  const handleChangeSendWithEnter = (e) => {
    props.appState.updateUserSettingsOnServer({
      ...userSettings,
      sendWithEnter: e,
    });
    setUserSettings({ ...userSettings, sendWithEnter: e });
  };

  const handleChangeNameFirst = (e) => {
    props.appState.updateUserSettingsOnServer({
      ...userSettings,
      nameFirst: e,
    });
    props.appState.setUsersDisplayName(null, e);
    setUserSettings({ ...userSettings, nameFirst: e });
  };

  const [text, setText] = useState("");

  function handleOnEnter(text) {
    console.log("enter", text);
  }

  const [userIdleTime, setUserIdleTime] = useState("");
  const [userIdleTimeFromBrowser, setUserIdleTimeFromBrowser] = useState("");

  useEffect(() => {
    setInterval(() => {
      setUserIdleTime(window.userIdleTime);
      setUserIdleTimeFromBrowser(window.userIdleTimeFromBrowser);
    }, 1000);
  }, []);

  return (
    <Modal size="lg" show={true} onHide={() => props.appState.setSettingsPanelVisible(0)} backdrop="static" keyboard={true}>
      <Modal.Header>
        <Modal.Title>Ρυθμίσεις</Modal.Title>
      </Modal.Header>

      <div className="settings-container">
        <div className="settings-item-on-off">
          <Switch width={45} height={22} className="switch" checked={userSettings.sendWithEnter} onChange={handleChangeSendWithEnter} />
          <span style={{ paddingLeft: "10px" }}>Αποστολή με ENTER</span>
        </div>
        <div className="settings-item-on-off">
          <Switch width={45} height={22} className="switch" checked={userSettings.nameFirst} onChange={handleChangeNameFirst} />

          <span style={{ paddingLeft: "10px" }}>Πρώτα όνομα μετά επίθετο</span>
        </div>
        <div className="settings-item">
          <label className="form-label">Dialer</label>
          <input
            style={{ display: "block", fontSize: "12px", width: "100%" }}
            spellCheck="false"
            defaultValue={userSettings.dialer ? userSettings.dialer : ""}
            onBlur={(e) => {
              setUserSettings({ ...userSettings, dialer: e.target.value });
              props.appState.updateUserSettingsOnServer({
                ...userSettings,
                dialer: e.target.value,
              });
            }}
          ></input>
        </div>
        <div className="settings-item">
          <label className="form-label">Μεγένθυνση</label>
          <input
            type="range"
            className="form-range"
            min="1"
            max="1.4"
            step=".005"
            id="customRange3"
            value={userSettings.zoom ? userSettings.zoom : 1}
            onChange={(e) => {
              setUserSettings({ ...userSettings, zoom: e.target.value });
              props.appState.updateUserSettingsOnServer({
                ...userSettings,
                zoom: e.target.value,
              });
            }}
          ></input>
        </div>
      </div>

      <Modal.Footer>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignContent: "baseline" }}>
          <div style={{ padding: "0 20px", opacity: ".3" }}>
            {userIdleTime} {userIdleTimeFromBrowser}
          </div>
          <Button variant="secondary" onClick={() => props.appState.setSettingsPanelVisible(0)}>
            ΟΚ
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SettingsPanel;
