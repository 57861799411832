import React, { useEffect, useState } from "react";
import "./Upload.css";
import { nanoid } from "nanoid";
import { MdCancel } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";

import fileLogo from "../../assets/images/file.png";
import docLogo from "../../assets/images/doc.png";
import csvLogo from "../../assets/images/csv.png";
import mp3Logo from "../../assets/images/mp3.png";
import mp4Logo from "../../assets/images/mp4.png";
import pdfLogo from "../../assets/images/pdf.png";
import zipLogo from "../../assets/images/zip.png";
import txtLogo from "../../assets/images/txt.png";
import xlsLogo from "../../assets/images/xls.png";

export default function UploadArea(props) {
  const [selectedfiles, setSelectedfiles] = useState([]);

  useEffect(() => {
    //   //initializeProgress(files.length);
    selectedfiles.filter((f) => f.uploadProgress === -1).forEach((f) => uploadFile(f, props.appState.rootURL + "/files"));
    selectedfiles.forEach(setPreviewData);

    handleChange();

    function setPreviewData(file) {
      if (!file.base64Data && (file.name.toLowerCase().endsWith("jpeg") || file.name.toLowerCase().endsWith("jpg") || file.name.toLowerCase().endsWith("png"))) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          setSelectedfiles((prevFiles) => {
            const tmp = [...prevFiles];
            var f = tmp.filter((f) => f.id === file.id)[0];
            if (f) {
              f.base64Data = reader.result;
            }
            return tmp;
          });
        };
      } else if (!file.base64Data && !(file.name.toLowerCase().endsWith("jpeg") || file.name.toLowerCase().endsWith("jpg") || file.name.toLowerCase().endsWith("png"))) {
        setSelectedfiles((prevFiles) => {
          const tmp = [...prevFiles];
          var f = tmp.filter((f) => f.id === file.id)[0];
          if (f) {
            f.base64Data = "no preview";
          }
          return tmp;
        });
      }
    }

    function updateProgress(fileId, percent, status, error) {
      setSelectedfiles((prevFiles) => {
        const tmp = [...prevFiles];
        var file = tmp.filter((f) => f.id === fileId)[0];
        if (file) {
          file.uploadProgress = percent;
          if (status !== undefined) {
            file.error = `${status} - ${error}`;
          }
        }
        return tmp;
      });
    }

    function uploadFile(file, url) {
      updateProgress(file.id, 0);

      var xhr = new XMLHttpRequest();

      setSelectedfiles((prevFiles) => {
        const tmp = [...prevFiles];
        var tmpfile = tmp.filter((f) => f.id === file?.id)[0];
        if (tmpfile) {
          tmpfile.uploader = xhr;
        }
        return tmp;
      });

      var formData = new FormData();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      // Update progress (can be used to show progress indicator)
      xhr.upload.addEventListener("progress", function (e) {
        if (selectedfiles.filter((f) => f.id === file.id).length === 0) {
          xhr.abort();
        } else {
          updateProgress(file.id, (e.loaded * 100.0) / e.total || 100);
        }
      });

      xhr.addEventListener("readystatechange", function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          updateProgress(file.id, 100); // <- Add this
        } else if (xhr.readyState === 4 && xhr.status !== 200) {
          updateProgress(file.id, 100, xhr.status, xhr.responseText);
        }
      });

      formData.append("auth", "jdtw4eutjaer95egje9458gbpweg936erigw49581");
      formData.append("id", file.id);
      //formData.append("user", props.userId);
      formData.append("file", file);
      xhr.send(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedfiles]);

  useEffect(() => {
    if (
      ((props.appState.newMessageData.files && props.appState.newMessageData.files.length > 0) || selectedfiles.length > 0) &&
      (props.appState.newMessageData.files?.filter((f) => selectedfiles?.filter((sf) => sf.id === f.id).length === 0).length > 0 ||
        selectedfiles?.filter((sf) => props.appState.newMessageData.files?.filter((f) => f.id === sf.id).length === 0).length > 0)
    ) {
      console.log(props.appState.newMessageData.files, props.appState.newMessageData.files.length);

      setSelectedfiles(() => {
        var tmpFiles = [...selectedfiles].filter((sf) => props.appState.newMessageData.files.filter((f) => f.id === sf.id).length === 1);

        props.appState.newMessageData.files.forEach((f) => {
          if (tmpFiles.filter((sf) => sf.id === f.id).length === 0) {
            tmpFiles.push({
              id: f.id,
              name: f.name,
              percent: 100,
              uploadProgress: 100,
              base64Data: `${props.appState.rootURL}/files/${f.id}_${f.name}`,
            });
          }
        });
        return tmpFiles;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appState.newMessageData]);

  function handleFiles(newfiles) {
    console.log("@@NewFiles");
    setSelectedfiles((files) => {
      let filesCopy = [...files];

      let tmpFiles = [...newfiles];

      tmpFiles.forEach((f) => {
        f.id = nanoid();
        f.uploadProgress = -1;
        filesCopy = [...filesCopy, f];
      });
      return filesCopy;
    });
  }

  window.clearFiles = () => {
    selectedfiles.forEach((f) => {
      if (f.percent !== 100) f.uploader.abort();
    });
    setSelectedfiles([]);
  };

  const handleDragOver = (e) => {
    let event = e;
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDragEnter = (e) => {
    let event = e;
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDrop = async (e) => {
    let event = e;

    if (e.ctrlKey) {
      return;
    }

    var length = e.dataTransfer.items.length;

    if (e.dataTransfer === null) {
      return;
    }

    for (var i = 0; i < length; i++) {
      var entry = e.dataTransfer.items[i].webkitGetAsEntry();

      if (entry) {
        if (entry.isFile) {
          console.log("isFile", entry);
          if (props.appState.winclient === "true") {
            return;
          }
        } else if (entry.isDirectory) {
          console.log(props.appState);
          if (props.appState.winclient !== "true") {
            event.stopPropagation();
            event.preventDefault();
          }

          return;
        }
      }
    }

    event.stopPropagation();
    event.preventDefault();

    var dt = event.dataTransfer;
    if (dt.files.length === 0) {
      var text = dt.getData("text");
      if (text && text !== "") props.appState.addTextToNewMessage(text);
    } else {
      handleFiles(dt.files);
    }
  };

  const handleChange = (e) => {
    try {
      props.onChange(
        e,
        e?.target.value,

        {
          files: selectedfiles.map((f) => {
            return { id: f.id, name: f.name };
          }),
        }
      );
    } catch {}
  };

  if (props.istextarea) {
    var tmp = { ...props };
    delete tmp.appState;

    return (
      <div
        className="dropzone"
        style={{ height: "100%", display: "flex", flexDirection: "column", position: "relative", overflow: "hidden" }}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onKeyDown={props.onKeyDown}
      >
        {selectedfiles.length > 0 && (
          <div className="message-selected-files">
            <GaleryX height="50" width="80" selectedfiles={selectedfiles} setSelectedfiles={setSelectedfiles} />
          </div>
        )}
        <div>
          <form id="attach-files-form">
            <input
              //webkitdirectory=""
              //directory=""
              id="fileElem"
              type="file"
              //accept="image/*"
              className="fileElem"
              multiple
              onChange={(e) => {
                handleFiles(e.target.files);
              }}
            />
          </form>
        </div>
        <div className="message-textarea-container">
          <textarea {...tmp} style={{ ...tmp.style, flexGrow: "1" }} />
        </div>
      </div>
    );
  } else
    return (
      <div
        className="dropzone"
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        style={{
          position: "relative",
          display: "block",
          userSelect: "none",
        }}
      >
        <div className="drop-area">
          <form className="my-form">
            <p className="note">
              Προσθέστε αρχεία σύροντας και ρίχνοντας τα στην διακεκομμένη <br />
              περιοχή ή χρησιμοποιήστε το παράθυρο διαλόγου
            </p>
            <input
              //webkitdirectory=""
              //directory=""
              id="fileElem"
              type="file"
              accept="image/*"
              className="fileElem"
              multiple
              onChange={(e) => {
                handleFiles(e.target.files);
              }}
            />
            <label className="button" htmlFor="fileElem">
              Επιλέξτε αρχεία
            </label>
            {/* {selectedfiles.length > 0 &&
            selectedfiles.filter((f) => f.uploadProgress !== 100).length ===
              0 && <span>ok</span>} */}
          </form>
          <div
            style={{
              position: "relative",
              top: "0px",
            }}
          >
            <GaleryX height="130" width="100" selectedfiles={selectedfiles} setSelectedfiles={setSelectedfiles} />
          </div>
        </div>
      </div>
    );
}

export const GaleryX = (props) => {
  const selectedfiles = props.selectedfiles;
  const setSelectedfiles = props.setSelectedfiles;

  return (
    <div className="gallery" style={{ margin: "1px", width: "100%" }}>
      {selectedfiles.map((f) => (
        <div
          key={f.id}
          style={{
            position: "relative",
            minWidth: props.width ? `${props.width}px` : "120px",
            minHeight: props.height ? `${props.height}px` : "190px",
            maxWidth: props.width ? `${props.width}px` : "120px",
            maxHeight: props.height ? `${props.height}px` : "190px",
            overflow: "hidden",
            margin: "1px",
            padding: "1px",
            backgroundColor: "lightgrey",
            userSelect: "none",
          }}
        >
          {f.base64Data && (
            <img
              style={{
                position: "relative",
                top: "3%",
                left: "3%",
                width: "94%",

                objectFit: "scale-down",
              }}
              src={(() => {
                if (f.name.toLowerCase().endsWith("jpeg") || f.name.toLowerCase().endsWith("jpg") || f.name.toLowerCase().endsWith("png")) return f.base64Data;
                else if (f.name.toLowerCase().endsWith("doc") || f.name.toLowerCase().endsWith("docx")) return docLogo;
                else if (f.name.toLowerCase().endsWith("xls") || f.name.toLowerCase().endsWith("xlsx")) return xlsLogo;
                else if (f.name.toLowerCase().endsWith("pdf")) return pdfLogo;
                else if (f.name.toLowerCase().endsWith("txt")) return txtLogo;
                else if (f.name.toLowerCase().endsWith("mp3")) return mp3Logo;
                else if (f.name.toLowerCase().endsWith("mp4")) return mp4Logo;
                else if (f.name.toLowerCase().endsWith("csv")) return csvLogo;
                else if (f.name.toLowerCase().endsWith("zip")) return zipLogo;
                else return fileLogo;
              })()}
              alt={f.name}
              title={f.name}
              onClick={(e) => {
                let url = f.base64Data;
                window.open(url, "_blank");
              }}
            />
          )}

          <div
            style={{
              position: "absolute",
              bottom: "0px",
              right: "0px",
              left: "0px",
              height: props.height === undefined || props.height > 100 ? "30px" : "17px",
              padding: "1px",
              backgroundColor: "lightgrey",
            }}
          >
            {(props.height === undefined || props.height > 100) && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                  wordWrap: "break-word",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  wordBreak: "normal",
                  fontSize: "10px",
                  lineHeight: "10px",
                  padding: "1px",
                  top: "2px",
                }}
              >
                {f.name}
              </div>
            )}

            <div
              style={{
                position: "absolute",

                right: "0px",
                width: "16px",
                lineHeight: "10px",
                bottom: "1.7px",
                padding: "0px",
                margin: "0px",
                fontSize: "14px",
                //backgroundColor: "yellow",
              }}
            >
              {(f.error || f.uploadProgress !== 100) && (
                <span
                  style={{
                    cursor: "pointer",
                    color: "#cc0000",
                  }}
                  onClick={() => {
                    f.uploader.abort();
                    setSelectedfiles((prevSelectedfiles) => [...prevSelectedfiles.filter((file) => file.id !== f.id)]);
                  }}
                >
                  <MdCancel />
                </span>
              )}
              {!f.error && f.uploadProgress === 100 && (
                <span
                  style={{
                    color: "#cc0000",
                  }}
                >
                  <MdCheckCircle />
                </span>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                left: "0px",
                right: "16px",
                lineHeight: "10px",
                bottom: "1.7px",

                margin: "0px",
                fontSize: "14px",

                padding: "0px 4px",
              }}
            >
              {f.error && (
                <span
                  style={{
                    wordWrap: "break-word",
                    fontSize: "10px",
                    color: "red",
                    marginTop: "4px",

                    float: "left",
                  }}
                >
                  {f.error}
                </span>
              )}
              {!f.error && f.uploadProgress === 100 && (
                <span
                  style={{
                    position: "absolute",
                    float: "left",
                    fontSize: "10px",
                    color: "red",
                    cursor: "pointer",
                    lineHeight: "5px",
                    margin: "0px",
                    bottom: "5px",
                  }}
                  onClick={() => {
                    setSelectedfiles((prevSelectedfiles) => [...prevSelectedfiles.filter((file) => file.id !== f.id)]);
                  }}
                >
                  Αφαίρεση
                </span>
              )}
              {!f.error && f.uploadProgress !== 100 && (
                <progress
                  style={{
                    bottom: "0",
                    float: "left",
                    display: "inline",
                    maxWidth: "100%",
                    marginTop: "3px",
                  }}
                  id="progress-bar"
                  max="100"
                  value={f.uploadProgress}
                ></progress>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

////////////////////////////////////////////////
export const Galery = (props) => {
  const selectedfiles = props.selectedfiles;
  const setSelectedfiles = props.setSelectedfiles;

  return (
    <div className="gallery" style={{ margin: "0px", width: "100%" }}>
      {selectedfiles.map((f) => (
        <div
          className="image-container"
          key={f.id}
          style={{
            width: props.width ? `${props.width}px` : "120px",
            maxWidth: props.width ? `${props.width}px` : "120px",

            height: props.height ? `${props.height}px` : "190px",
            maxHeight: props.height ? `${props.height}px` : "190px",

            margin: props.margin ? `${props.margin}px` : "5px",
            padding: props.padding ? `${props.padding}px` : "5px",
          }}
        >
          <div
            style={{
              display: "block",
              minHeight: props.height ? `${props.height}px` : "120px",

              width: props.width ? `${props.width}px` : "120px",
              backgroundColor: "yellow",
            }}
          >
            {f.base64Data && (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={(() => {
                  if (f.name.toLowerCase().endsWith("jpeg") || f.name.toLowerCase().endsWith("jpg") || f.name.toLowerCase().endsWith("png")) return f.base64Data;
                  else if (f.name.toLowerCase().endsWith("doc") || f.name.toLowerCase().endsWith("docx")) return docLogo;
                  else if (f.name.toLowerCase().endsWith("xls") || f.name.toLowerCase().endsWith("xlsx")) return xlsLogo;
                  else if (f.name.toLowerCase().endsWith("pdf")) return pdfLogo;
                  else if (f.name.toLowerCase().endsWith("txt")) return txtLogo;
                  else if (f.name.toLowerCase().endsWith("mp3")) return mp3Logo;
                  else if (f.name.toLowerCase().endsWith("mp4")) return mp4Logo;
                  else if (f.name.toLowerCase().endsWith("csv")) return csvLogo;
                  else if (f.name.toLowerCase().endsWith("zip")) return zipLogo;
                  else return fileLogo;
                })()}
                alt={f.name}
                title={f.name}
                onClick={(e) => {
                  var w = window.open("");
                  w.document.body.innerHTML = `<img src="${f.base64Data}" width="100%" >`;
                }}
              />
            )}
          </div>
          <div
            style={{
              maxWidth: props.width ? `${props.width}px` : "120px",
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              wordBreak: "normal",
              fontSize: "10px",
              lineHeight: "10px",
              marginTop: "10px",
            }}
          >
            {f.name}
          </div>

          <div
            style={{
              display: "inline-block",
              maxWidth: props.width ? `${props.width}px` : "120px",
              padding: "3px",
              position: "absolute",
              bottom: "0",
            }}
          >
            {!f.error && f.uploadProgress !== 100 && (
              <>
                <span
                  style={{
                    cursor: "pointer",
                    display: "inline",
                    float: "right",
                    fontSize: "14px",
                    color: "#cc0000",
                    margin: "0",
                  }}
                  onClick={() => {
                    f.uploader.abort();
                    setSelectedfiles((prevSelectedfiles) => [...prevSelectedfiles.filter((file) => file.id !== f.id)]);
                  }}
                >
                  <MdCancel />
                </span>
                <progress
                  style={{
                    bottom: "0",
                    float: "left",
                    display: "inline",
                    maxWidth: "70%",
                    marginTop: "3px",
                  }}
                  id="progress-bar"
                  max="100"
                  value={f.uploadProgress}
                ></progress>
              </>
            )}
            {f.error && (
              <>
                <span
                  style={{
                    wordWrap: "break-word",
                    fontSize: "10px",
                    color: "red",
                    marginTop: "4px",

                    float: "left",
                  }}
                >
                  {f.error}
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    display: "inline",
                    float: "right",
                    fontSize: "14px",
                    color: "#cc0000",
                    margin: "0",
                  }}
                  onClick={() => {
                    f.uploader.abort();
                    setSelectedfiles((prevSelectedfiles) => [...prevSelectedfiles.filter((file) => file.id !== f.id)]);
                  }}
                >
                  <MdCancel />
                </span>
              </>
            )}
            {!f.error && f.uploadProgress === 100 && (
              <>
                <div
                  style={{
                    display: "flow",
                    float: "right",
                    fontSize: "14px",
                    color: "#cc0000",
                    margin: "0",
                  }}
                >
                  <MdCheckCircle />
                </div>

                <span
                  style={{
                    float: "left",
                    wordWrap: "break-word",
                    fontSize: "10px",
                    color: "green",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedfiles((prevSelectedfiles) => [...prevSelectedfiles.filter((file) => file.id !== f.id)]);
                  }}
                >
                  Αφαίρεση
                </span>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
