import React from "react";

import { FiSearch } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";

const MainBar = (props) => {
  const setSearchPanelVisible = props.appState.setSearchPanelVisible;
  const mainTabs = props.appState.mainTabs;
  const activeMainTab = props.appState.activeMainTab;
  const setActiveMainTab = props.appState.setActiveMainTab;

  return (
    <div
      className="icon-bar bar-tab"
      style={{
        paddingLeft: "3px",
        paddingRight: "0px",
        maxHeight: "25px",
        minHeight: "25px",
      }}
    >
      {/* <div className="search-box">
        <input
          className="search-input"
          placeholder="Φίλτρο"
          value={messageFilter}
          onChange={(e) => {
            setMessageFilter(e.target.value);
          }}
        />
        <span
          className="search-icon"
          onClick={() => {
            setSearchPanelVisible(1);
          }}
        >
          <FiSearch />
        </span>
      </div> */}

      {mainTabs.map((t, index) => (
        <div
          key={index}
          className={`main-bar-tab-item${t.id === activeMainTab ? " active" : ""}`}
          onClick={() => {
            if (t.id) setActiveMainTab(t.id);
          }}
        >
          <div className="main-bar-tab-item-title">{t.name}</div>
          {t.id !== "0" && (
            <div className="main-bar-tab-item-close"
              
              onClick={(e) => {
                e.stopPropagation();
                props.appState.setMainTabs(props.appState.mainTabs.filter((tt) => tt.id !== t.id));
                setActiveMainTab("0");
              }}
            >
              <TiDelete />
            </div>
          )}
        </div>
      ))}

      <div style={{ flex: "1" }}>
        <span
          style={{ float: "right" }}
          className="main-bar-icon"
          onClick={() => {
            setSearchPanelVisible(1);
          }}
        >
          <FiSearch />
        </span>
      </div>
    </div>
  );
};

export default MainBar;
