import React, { useEffect, useRef, useState } from "react";
import UploadArea from "./Upload/UploadArea";
import AudioPlayer, { AudioRecorder } from "./Audio";

const emoji = [
  { id: "🙏", name: "Folded Hands" },
  { id: "💯", name: "Hundred Points" },
  { id: "🔥", name: "Fire" },
  { id: "✔️", name: "Hundred Points1" },
  { id: "👍", name: "Thumbs Up" },

  { id: "😂", name: "Face with Tears of Joy" },
  { id: "😭", name: "Loudly Crying Face" },
  { id: "😁", name: "Smiling Face with Hearts" },
  { id: "🤣", name: "Rolling on the Floor Laughing" },
  { id: "😍", name: "Smiling Face with Heart-Eyes" },

  { id: "😊", name: "Smiling Face with Smiling Eyes" },
  { id: "🥰", name: "Smiling Face with Hearts1" },
  { id: "😎", name: "Smiling Face with Sunglasses" },
  { id: "😈", name: "Smiling Face with Horns" },
  { id: "😜", name: "Winking Face with Tongue" },

  { id: "😡", name: " Angry Face" },
  { id: "🤒", name: "Face with Thermometer" },
  { id: "🤐", name: "Zipper-Mouth Face" },
  { id: "🥱", name: "Yawning Face" },
  { id: "😴", name: "Sleeping Face" },

  { id: "😲", name: "Shocked Emoji" },
  { id: "😥", name: "Sad But Relieved" },
  { id: "😵", name: "Dizzy Face" },
  { id: "🤒", name: "Face with Thermometer1" },
  { id: "😱", name: "Face Screaming in Fear" },

  { id: "❤️", name: "Red Heart" },
  { id: "✨", name: "Sparkles" },
  { id: "👏", name: "Clapping Hands" },
  { id: "🎉", name: "Party Popper" },
  { id: "🍕", name: "Pizza" },
];

const MessagePanel = (props) => {
  const handleSendMessage = props.appState.handleSendMessage;
  const newMessageData = props.appState.newMessageData;
  const setNewMessageData = props.appState.setNewMessageData;

  const clear = (e) => {
    if (blob) {
      setBlob(null);
      return;
    }

    props.appState.clear(e);
  };
  const userSettings = props.appState.userSettings;

  const handleNewMessageChange = (event, value, files) => {
    if (files != null) setNewMessageData(files);
  };

  const addTextToNewMessage = props.appState.addTextToNewMessage;

  const [blob, setBlob] = useState();
  window.messageAudioBlob = blob;
  window.messageSetAudioBlob = setBlob;

  return (
    <div className="message-panel">
      <div id="send" className="send-message-button-container">
        <div className="send-message-buttons">
          <div className="send-message-buttons-emoji-container">
            {emoji.map((e) => (
              <span
                key={e.name}
                className="message-bar-emojy-icon"
                role="img"
                aria-label={e.name}
                title={e.name}
                onClick={() => {
                  addTextToNewMessage(e.id);
                }}
              >
                {e.id}
              </span>
            ))}
          </div>
          <button onClick={(e) => clear(e)} className="clear-message-button">
            Καθάρισμα
          </button>
          <button onClick={() => handleSendMessage(newMessageData, setNewMessageData)} className="send-message-button">
            Αποστολή
          </button>
        </div>
      </div>

      <div className="message-textarea-container">
     
          <UploadArea
            {...props}
            istextarea="true"
            onKeyDown={(e) => {
              if (userSettings.sendWithEnter && e.keyCode === 13 && !e.ctrlKey && !e.shiftKey && !e.altKey) {
                if (e.repeat) return;
                e.preventDefault();
                if (document.getElementById("message-textarea").value.toString().trim() !== "") handleSendMessage(newMessageData, setNewMessageData);
              }
            }}
            //ref={props.appState.inputEl}
            className="dropzone message-textarea custom-scroll"
            spellCheck="true"
            id="message-textarea"
            onChange={handleNewMessageChange}
            placeholder="Γράψε εδώ το μήνυμά σου..."
          />

          {props.appState.showRecorder && (
            <div className="audio-recorder-player-container">
              <AudioRecorder setBlob={setBlob} />
              {blob && <AudioPlayer blob={blob} key={blob} />}
            </div>
          )}
      
      </div>
    </div>
  );
};

export default MessagePanel;
