import React, { useEffect, useState } from "react";

import InfiniteScrollPanel from "./InfiniteScrollPanel";

import SidePanel from "./SidePanel";
import MessagePanel from "./MessagePanel";
import MainBar from "./MainBar";

import MessageBar from "./MessageBar";
import SettingsPanel from "../SettingsPanel";
import SearchPanel from "./SearchPanel";
import PersonalGroupModal from "./PersonalGroupModal";
import { Modal, Button } from "react-bootstrap";

const Home = (props) => {
  const appState = props.appState;
  const userSettings = appState.userSettings;
  const settingsPanelVisible = appState.settingsPanelVisible;
  const searchPanelVisible = appState.searchPanelVisible;
  const personalGroupPanel = appState.personalGroupPanel;
  const modalData = appState.modalData;
  const setModalData = appState.setModalData;
  const inputPanelHeight = appState.inputPanelHeight;

  const handleClose = () => setModalData(null);

  const messageBoxPanelRef = React.useRef(null);
  const sidePanelRef = React.useRef(null);
  const mainPanelRef = React.useRef(null);
  const topBorderRef = React.useRef(null);
  const leftBorderRef = React.useRef(null);
  const homeRef = React.useRef(null);

  const [mobileView, setMobileView] = [appState.mobileView, appState.setMobileView];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const mobile = false;

  useEffect(() => {
    if (mobile) return;

    let initialMouseY = 0;
    let initialMouseX = 0;
    let initialHeight = 0;
    let initialWidth = 0;

    function resizeWindow() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", resizeWindow);

    leftBorderRef.current.addEventListener("mousedown", (event) => {
      initialMouseX = event.clientX;
      initialWidth = sidePanelRef.current.offsetWidth;

      leftBorderRef.current.classList.add("resize-border-active");
      homeRef.current.classList.add("resizing-column");

      document.addEventListener("mousemove", resizeSizePanel);
      document.addEventListener("mouseup", removeSidePanelListeners);
    });

    topBorderRef.current.addEventListener("mousedown", (event) => {
      initialMouseY = event.clientY;
      initialHeight = messageBoxPanelRef.current.offsetHeight;

      topBorderRef.current.classList.add("resize-border-active");
      homeRef.current.classList.add("resizing-row");

      document.addEventListener("mousemove", resizePanel);
      document.addEventListener("mouseup", removeListeners);
    });

    function resizePanel(event) {
      console.log("resizePanel", initialHeight);
      let newHeight = initialHeight + (initialMouseY - event.clientY);
      if (newHeight < 104) newHeight = 104;
      if (newHeight > 300) newHeight = 300;
      messageBoxPanelRef.current.style.minHeight = newHeight + "px";
    }

    function resizeSizePanel(event) {
      let newWidth = initialWidth + (initialMouseX - event.clientX);
      if (newWidth < 240) newWidth = 240;
      if (newWidth > 320) newWidth = 320;
      sidePanelRef.current.style.maxWidth = newWidth + "px";
      sidePanelRef.current.style.minWidth = newWidth + "px";

      mainPanelRef.current.style.maxWidth = "calc(100vw - " + newWidth + "px)";
      mainPanelRef.current.style.minWidth = "calc(100vw - " + newWidth + "px)";
    }

    function removeListeners() {
      homeRef.current.classList.remove("resizing-row");
      topBorderRef.current.classList.remove("resize-border-active");
      document.removeEventListener("mousemove", resizePanel);
    }

    function removeSidePanelListeners() {
      homeRef.current.classList.remove("resizing-column");
      leftBorderRef.current.classList.remove("resize-border-active");
      document.removeEventListener("mousemove", resizeSizePanel);
    }

    return () => {
      document.removeEventListener("mousemove", resizePanel);
      document.removeEventListener("mousemove", resizeSizePanel);
      document.removeEventListener("mouseup", removeListeners);
      document.removeEventListener("mouseup", removeSidePanelListeners);
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  useEffect(() => {
    if (mobile) return;
    messageBoxPanelRef.current.style.minHeight = inputPanelHeight + "px";
  }, [inputPanelHeight, mobile]);

  useEffect(() => {
    if (windowWidth < 501) {
      mainPanelRef.current.style.maxWidth = "100%";
      mainPanelRef.current.style.minWidth = "100%";
    } else {
      mainPanelRef.current.style.maxWidth = "calc(100vw - " + sidePanelRef.current.offsetWidth + "px)";
      mainPanelRef.current.style.minWidth = "calc(100vw - " + sidePanelRef.current.offsetWidth + "px)";
    }
  }, [windowWidth, mobile]);

  if (mobile) {
    return (
      <div className={"version-4 messenger-container app-container" + (userSettings.darkTheme ? "-dark dark" : "")}>
        {settingsPanelVisible === 1 && <SettingsPanel appState={appState} />}
        {searchPanelVisible === 1 && <SearchPanel appState={appState} />}
        {personalGroupPanel && <PersonalGroupModal appState={appState} />}
        {modalData && (
          <Modal size="sm" show={true} onHide={handleClose} backdrop="static" keyboard={true}>
            <Modal.Header>
              <Modal.Title>{modalData.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalData.msg}</Modal.Body>

            <Modal.Footer>
              {modalData.isQuestion && (
                <Button
                  className="rc-button-cancel"
                  variant="secondary"
                  onClick={() => {
                    modalData.onClose && modalData.onClose();
                    handleClose();
                  }}
                >
                  Όχι
                </Button>
              )}

              <Button
                className="rc-button-ok"
                onClick={() => {
                  modalData.onOk && modalData.onOk();
                  handleClose();
                }}
              >
                {modalData.isQuestion ? "Ναι" : "ΟΚ"}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <MainBar appState={appState} />
        <SidePanel appState={appState} />
        <InfiniteScrollPanel appState={appState} />

        {/* <InfiniteScrollPanel appState={appState} />
         <MessageBar appState={appState} /> 
        <MessagePanel appState={appState} /> */}
      </div>
    );
  } else
    return (
      <div ref={homeRef} className={"version-4 home-container app-container" + (userSettings.darkTheme ? "-dark" : "")}>
        {settingsPanelVisible === 1 && <SettingsPanel appState={appState} />}
        {searchPanelVisible === 1 && <SearchPanel appState={appState} />}
        {personalGroupPanel && <PersonalGroupModal appState={appState} />}

        {modalData && (
          <Modal size="sm" show={true} onHide={handleClose} backdrop="static" keyboard={true}>
            <Modal.Header>
              <Modal.Title>{modalData.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalData.msg}</Modal.Body>

            <Modal.Footer>
              {modalData.isQuestion && (
                <Button className="rc-button-cancel" variant="secondary" onClick={handleClose}>
                  Όχι
                </Button>
              )}

              <Button
                className="rc-button-ok"
                onClick={() => {
                  modalData.onOk && modalData.onOk();
                  handleClose();
                }}
              >
                {modalData.isQuestion ? "Ναι" : "ΟΚ"}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <div className="top-panel">
          <div className="top-version">v:{window.version}</div>

          <button className="top-button" onClick={() => setMobileView((prev) => !prev)}>
            {mobileView ? "Μηνύματα" : " Χρήστες"}
          </button>
        </div>

        <div className="bottom-panel">
          <>
            <div ref={mainPanelRef} className={`main-panel${mobileView ? " show-user-panel" : ""}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  flexGrow: "1",

                  backgroundColor: "var(--rm-msg-panel-bg)",
                }}
              >
                <div style={{ width: "100%", position: "relative" }}>
                  <MainBar appState={appState} />
                </div>
                <div
                  style={{
                    flexGrow: "1",
                    width: "100%",

                    overflow: "auto",
                    position: "relative",
                    padding: "2px 0 0 0",
                  }}
                >
                  <InfiniteScrollPanel appState={appState} />
                </div>

                <div id="message-top-border" className="resize-border row" ref={topBorderRef} />

                <div
                  ref={messageBoxPanelRef}
                  id="message-box-panel"
                  className="message-box-panel"
                  style={{
                    height: inputPanelHeight,
                  }}
                >
                  <MessageBar appState={appState} />

                  <div style={{ flexGrow: "1", overflow: "hidden", height: "100%" }}>
                    <MessagePanel appState={appState} />
                  </div>
                </div>
              </div>
            </div>
            <div className={`side-panel-container${mobileView ? " show-user-panel" : ""}`}>
              <div id="sidebar-left-border" className="resize-border column" ref={leftBorderRef} />

              <div
                className={`side-panel${mobileView ? " show-user-panel" : ""}`}
                ref={sidePanelRef}
                style={{ height: "100%", maxWidth: mobileView ? "100%" : "240px", overflow: "hidden", position: "relative", minWidth: mobileView ? "100%" : "240px" }}
              >
                <SidePanel appState={appState} />
              </div>
            </div>
          </>
        </div>
      </div>
    );
};

export default Home;
