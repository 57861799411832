import React from "react";
import { TiDelete } from "react-icons/ti";

import { FiCheckSquare, FiSquare } from "react-icons/fi";
const UsersBarBottom = (props) => {
  const usersFilter = props.appState.usersFilter;
  const setUsersFilter = props.appState.setUsersFilter;

  const multiSelect = window.getAppValue("multiSelect");

  return (
    <div className="bottom-users-bar">
      <div
        className={`multi-select-buttton${multiSelect ? " selected" : ""}`}
        title="Πολλαπλή επιλογή"
        onClick={() => {
          window.setAppValue("multiSelect", !multiSelect);
          if (props.appState.mobileView === true && multiSelect) props.appState.setMobileView(false);
        }}
      >
        {props.appState.mobileView && multiSelect && <>OK</>}
        {!props.appState.mobileView && multiSelect && <FiCheckSquare />}

        {!multiSelect && <FiSquare />}
      </div>
      <div className="user-filter-input-container">
        <input
          style={{ width: "100%" }}
          className="user-filter-input"
          placeholder="Φίλτρο"
          value={usersFilter}
          onChange={(e) => {
            setUsersFilter(e.target.value);
          }}
        />

        {usersFilter && usersFilter !== "" && (
          <div
            className="user-filter-input-clear"
            onClick={() => {
              setUsersFilter("");
            }}
          >
            <TiDelete />
          </div>
        )}
      </div>
      <div className="users-count" title="Ενεργοί / Συνολικοί Χρήστες">
        {/* get the count of users that has connection ( userId in props.appState?.connections*/}
        {props.appState?.users?.filter((u) => props.appState?.connections?.find((c) => c.userId === u.id)).length}/{props.appState?.users?.filter(u=>u.status===1).length}
      </div>
    </div>
  );
};

export default UsersBarBottom;
