import React, { useState } from "react";
import AudioPlayer, {AudioRecorder} from "./Audio";


export default function Upload(props) {
  const [blob, setBlob] = useState();

  return (
    <div>
      <div className="audio-recorder-player-container">
          <AudioRecorder setBlob={setBlob} />
          {blob && <AudioPlayer blob={blob} key={blob} />}
      </div>
    </div>
  );
}
