import React, { useEffect, useRef, useState, useMemo } from "react";
import { BiPlay, BiPause, BiStop, BiMicrophone } from "react-icons/bi";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";

import styled, { keyframes } from "styled-components";

import "./Audio.css";

const AudioPlayer = (props) => {
  const [blob, setBlob] = useState(props.blob);

  const audioPlayerRef = useRef(null);

  const [duration, setDuration] = useState(undefined);

  const [playing, setPlaying] = useState(0);

  function getSoundDuration(blob) {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const reader = new FileReader();

      // Read the Blob and convert it to an audio buffer
      reader.onload = function (e) {
        const arrayBuffer = e.target.result;
        audioContext.decodeAudioData(arrayBuffer, function (audioBuffer) {
          // Get the duration of the audio in seconds
          const durationInSeconds = audioBuffer.duration;

          // Close the audio context to free up resources
          audioContext.close().then(() => {
            resolve(durationInSeconds);
          });
        });
      };

      // Read the Blob as an ArrayBuffer
      reader.readAsArrayBuffer(blob);

      reader.onerror = function (err) {
        reject(err);
      };
    });
  }

  useEffect(() => {
    console.log("playing", playing);
  }, [playing]);

  const handlePlayPause = () => {
    if (audioPlayerRef.current) {
      if (!audioPlayerRef.current.paused) {
        audioPlayerRef.current.pause();
        setPlaying(1);
      } else {
        audioPlayerRef.current.play();
        setPlaying(2);
      }
    }
  };

  useEffect(() => {
    if (props.src) {
      //get blob from src
      fetch(props.src)
        .then((res) => res.blob())
        .then((blob) => {
          setBlob(blob);
        });
    }
  }, []);

  useEffect(() => {
    setDuration(undefined);
    async function fetchAudioDuration() {
      try {
        const duration = await getSoundDuration(blob); // Assuming you have audioBlob defined
        setDuration(duration);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    // Call the asynchronous function
    fetchAudioDuration();
  }, [blob]);

  const audioElement = useMemo(() => {
    if (!blob) return <></>;
    return (
      <audio
        src={URL.createObjectURL(blob)}
        ref={audioPlayerRef}
        onEnded={() => {
          setPlaying(0);
        }}
        onPlay={() => {
          setPlaying(2);
        }}
        onPause={() => {
          setPlaying(1);
        }}
      />
    );
  }, [blob]);

  return (
    <>
      {blob && (
        <div className="audio-player-container">
          <div
            className={`audio-player-button${playing !== 2 ? " playing" : ""}`}
            onClick={() => {
              handlePlayPause();
            }}
          >
            {playing === 2 && <BiPause />}

            {playing !== 2 && <BiPlay />}
          </div>

          <AudioPlayerVisualizer duration={duration} playerRef={audioPlayerRef} blob={blob} height={25} barWidth={3} gap={2} barColor={"#555"} />
        </div>
      )}

      {audioElement}

      {/* <button
        className="audio-button-play"
        onClick={() => {
          handlePlayPause();
        }}
      >
        {playing !== 2 ? "Play" : "Pause"}
      </button>

      {playing !== 0 && (
        <button
          className="audio-button-play"
          onClick={() => {
            audioPlayerRef.current.pause();
            audioPlayerRef.current.currentTime = 0;
          }}
        >
          Stop
        </button>
      )} */}
    </>
  );
};

const AudioPlayerVisualizer = (props) => {
  const visualizerRef = useRef(null);
  const [CurrentTime, setCurrentTime] = useState(props.currentTime ?? 0);

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setParentWidth(visualizerRef.current?.parentElement.offsetWidth - 48);
      }, 100);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    var timer = setInterval(() => {
      try {
        if (props.playerRef.current) {
          const ct = props.playerRef.current.currentTime;
          //console.log(ct);
          setCurrentTime(ct);
        }
      } catch {}
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="audio-player-time">
        {/* convert current time to minutes and seconds*/}
        {Math.floor(CurrentTime / 60)}:
        {Math.floor(CurrentTime % 60)
          .toString()
          .padStart(2, "0")}{" "}
        / {Math.floor(props.duration / 60)}:
        {Math.floor(props.duration % 60)
          .toString()
          .padStart(2, "0")}
      </div>
      <div
        ref={visualizerRef}
        className="audio-player-visualizer"
        onClick={(e) => {
          const rect = e.target.getBoundingClientRect();
          const x = e.clientX - rect.left; //x position within the element.
          const y = e.clientY - rect.top; //y position within the element.
          console.log(x, y);
          const percent = x / parentWidth;
          console.log(percent);
          const time = props.duration * percent;
          console.log(time);
          props.playerRef.current.currentTime = time;
        }}
      >
        <AudioVisualizer key={parentWidth} {...props} currentTime={CurrentTime} width={parentWidth} />
      </div>
    </>
  );
};

const flashingAnimation = keyframes`
  0% {
    opacity: 1;

  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 1;
    // transform: scale(.8);
    color: #faa;
  }
  60% {
    opacity: 1;
    color: #faa;
    
  }
  90% {
    opacity: 1;

    transform: scale(1);
  }

  100% {
    opacity: 1;
  }
`;
const MicrophoneIcon = styled(BiMicrophone)`
  animation: ${flashingAnimation} 1s linear infinite;
`;

const AudioRecorder = (props) => {
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const audioRecorderRef = useRef(null);

  const mediaRecorder = useRef(null);
  const mediaChunks = useRef([]);
  const recordingInterval = useRef(null);

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setParentWidth(audioRecorderRef.current.offsetWidth - 90);
      }, 100);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          mediaChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(mediaChunks.current, { type: "audio/webm" });
        props.setBlob ? props.setBlob(blob) : console.log("no setBlob");
        mediaChunks.current = [];
      };

      mediaRecorder.current.start();
      setRecording(true);

      // Start the recording time interval
      recordingInterval.current = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000); // Update every second
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setPaused(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && (mediaRecorder.current.state === "recording" || mediaRecorder.current.state === "paused")) {
      mediaRecorder.current.stop();
      setRecording(false);
      setPaused(false);

      // Clear the recording time interval
      clearInterval(recordingInterval.current);
    }
  };

  return (
    <div ref={audioRecorderRef} className={`audio-recorder-container${recording ? " recording" : ""}`}>
      <div
        className={`audio-player-button${recording ? " recording" : ""}${paused ? " paused" : ""}`}
        onClick={() => {
          if (recording) {
            //paused ? resumeRecording() : pauseRecording();
            stopRecording();
          } else {
            setElapsedTime(0);
            startRecording();
            props.setBlob ? props.setBlob(null) : console.log("no setBlob");
          }
        }}
      >
        {/* {recording && !paused && <BiPause />}
          {recording && paused && <BiPlay />} */}
        {recording && <MicrophoneIcon size="23" color="red" />}
        {!recording && <BiMicrophone size="23" color="#333" />}
      </div>

      {/* <div
          className={`audio-player-button stop-button${recording ? " active" : ""}`}
          onClick={() => {
            stopRecording();
          }}
        >
          <BiStop />
        </div> */}

      <div className="audio-recording-time">{recording && <span>{formatTime(elapsedTime)}</span>}</div>

      {mediaRecorder.current && (recording || paused) && (
        <LiveAudioVisualizer width={parentWidth} ref={audioRecorderRef} mediaRecorder={mediaRecorder.current} height={20} barColor="#555" barWidth={3} gap={2} />
      )}
    </div>
  );
};

// Helper function to format time in HH:MM:SS format
function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours > 0 ? `${hours}:` : ""}${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
}

export default AudioPlayer;
export { AudioRecorder };
