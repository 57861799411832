import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./index.css";
import Test from "./v4/test";

///////////////////////////////////////////////////////////////////////////////
import ChatRoom from "./v4/ChatRoom";
import ChatRoomBeta from "./v4/ChatRoom";

const packageJson = require("../package.json");
window.version = packageJson.version;

function App(props) {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => {
            console.log(localStorage.getItem("isBeta"));
            if (localStorage.getItem("isBeta")) {
              return <ChatRoomBeta winclient="false" webclient="true" {...props} />;
            } else {
              return <ChatRoom winclient="false" webclient="fasle" {...props} />;
            }
          }}
        />
        <Route exact path="/web/:urlId" render={(props) => <ChatRoom winclient="false" webclient="true" {...props} />} />
        <Route exact path="/winclient/:urlId" render={(props) => <ChatRoom winclient="true" webclient="false" {...props} />} />
        <Route exact path="/winclient/:urlId/:clientVersion" render={(props) => <ChatRoom winclient="true" webclient="false" {...props} />} />

        <Route exact path="/betaweb/:urlId" render={(props) => <ChatRoomBeta winclient="false" webclient="true" {...props} />} />
        <Route exact path="/betawinclient/:urlId" render={(props) => <ChatRoomBeta winclient="true" webclient="false" {...props} />} />
        <Route exact path="/betawinclient/:urlId/:clientVersion" render={(props) => <ChatRoomBeta winclient="true" webclient="false" {...props} />} />

        <Route exact path="/upload" component={Test} />

        <Route exact path="/test" component={Test} />
      </Switch>
    </Router>
  );
}

export default App;
