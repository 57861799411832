import React from "react";


import { FiSettings } from "react-icons/fi";
import { FiSun } from "react-icons/fi";
import { FiMoon } from "react-icons/fi";


const UsersBar = (props) => {
  const userSettings = props.appState.userSettings;
  const setUserSettings = props.appState.setUserSettings;
  const setSettingsPanelVisible = props.appState.setSettingsPanelVisible;
  const setUsersTab = props.appState.setUsersTab;
  const usersTab = props.appState.usersTab;
  const getConversations = props.appState.getConversations;

  return (
    <div

      className="icon-bar bar-tab"
    >
      <div
        className={`bar-tab-item${usersTab === 0 ? " active" : ""}`}
        onClick={() => {
          if (!window.getAppState().conversations) getConversations();
          setUsersTab(0);
        }}
      >
        Συνομιλίες
      </div>
      <div
        className={`bar-tab-item${usersTab === 1 ? " active" : ""}`}
        onClick={() => setUsersTab(1)}
      >
        Χρήστες
      </div>
      <div
        className={`bar-tab-item${usersTab === 2 ? " active" : ""}`}
        onClick={() => setUsersTab(2)}
      >
        Ομάδες
      </div>


      <div style={{ width: "100%", padding:"2px 5px 0 0"}}>
        <span
          style={{ float: "right" }}
          className="user-bar-icon"
          title="Ρυθμίσεις"
          onClick={() => {
            setSettingsPanelVisible(1);
          }}
        >
          <FiSettings />
        </span>
        {!userSettings.darkTheme && (
          <span
            style={{ float: "right" }}
            className="user-bar-icon"
            onClick={() => {
              props.appState.updateUserSettingsOnServer({
                ...userSettings,
                darkTheme: true,
              });
              setUserSettings({ ...userSettings, darkTheme: true });
            }}
          >
            <FiSun />
          </span>
        )}
        {userSettings.darkTheme && (
          <span
            style={{ float: "right" }}
            className="user-bar-icon"
            onClick={() => {
              props.appState.updateUserSettingsOnServer({
                ...userSettings,
                darkTheme: false,
              });
              setUserSettings({ ...userSettings, darkTheme: false });
            }}
          >
            <FiMoon />
          </span>
        )}
      </div>
 
    </div>
  );
};

export default UsersBar;
