import React, {useState} from "react";
import { Modal, Button } from "react-bootstrap";
import HistoryPanel from "./InfiniteScrollPanel";

const SearchPanel = (props) => {

  const [filter, setFilter] = useState("");
  return (
    <Modal
      size="xl"
      show={true}
      onHide={() => props.appState.setSearchPanelVisible(0)}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header>
        <Modal.Title>Αναζήτηση</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
      

          <input
            value={filter}
            onChange={(e) => { setFilter(e.target.value) }}
          />
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          
          window.Search(filter,props.appState.activeMainTab);
          props.appState.setSearchPanelVisible(0);}}>
          ΟΚ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchPanel;
