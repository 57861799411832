import React, { useEffect, useRef } from "react";

export const getUserFullName = (userId, option, users, nameFirst) => {

  var out;

  const user = users.filter(
    (u) => (u.id ? u.id.toString() : u.toString()) === userId.toString()
  )[0];
  if (user)
    out = nameFirst
      ? user.firstName + " " + user.lastName
      : user.lastName + " " + user.firstName;
  else out = "...";

  if (option === "gen") {
    out = out.split("ις").join("*is");
    out = out.split("ους").join("*oys");
    out = out.split("εύς").join("*eys");

    out = out.split("ς").join("");

    out = out.split("*is").join("ις");
    out = out.split("*oys").join("ους");
    out = out.split("*eys").join("έα");

    
  }

  return out;
};

export const stripHtml = (str) => {
  var tmp = document.createElement("pre");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

export const getUserDisplayName = (userId, option, users) => {
  
  const tmpUser = users.filter((u) => u.id === userId)[0];
  if (tmpUser) return tmpUser.lastName + " " + tmpUser.firstName;
  else return "...";
};

export const stingForFilter = (txt) => {
  txt = txt.toLowerCase();
  txt = txt.split("ά").join("α");
  txt = txt.split("έ").join("ε");
  txt = txt.split("ή").join("η");
  txt = txt.split("ί").join("ι");
  txt = txt.split("ύ").join("υ");
  txt = txt.split("ό").join("ο");
  txt = txt.split("ώ").join("ω");

  return txt;
};

export const ensureInView = (container, element) => {
  //Determine container top and bottom
  let cTop = container.scrollTop;
  let cBottom = cTop + container.clientHeight;

  //Determine element top and bottom
  let eTop = element.offsetTop;
  let eBottom = eTop + element.clientHeight;

  //Check if out of view
  if (eTop < cTop) {
    container.scrollTop -= cTop - eTop;
  } else if (eBottom > cBottom) {
    container.scrollTop += eBottom - cBottom;
  }
};

export const isVisible = function (ele, container) {
  const eleTop = ele.offsetTop;
  const eleBottom = eleTop + ele.clientHeight;

  const containerTop = container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  // The element is fully visible in the container
  return (
    (eleTop >= containerTop && eleBottom <= containerBottom) ||
    // Some part of the element is visible in the container
    (eleTop < containerTop && containerTop < eleBottom) ||
    (eleTop < containerBottom && containerBottom < eleBottom)
  );
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}